import { twMerge } from 'tailwind-merge';

/**
 *
 * @param className additional styles to override with
 * @param style base styles
 * @returns className
 */
export const mergeStyles = (className: string | undefined, style: string) =>
  twMerge(style, className);
