import { useSWRConfig } from 'swr';

import { SWR_KEYS } from '@lib/constants';

const logout = async (body) =>
  fetch('/api/customer/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

export function useLogout(redirectTo?: string) {
  const { mutate } = useSWRConfig();
  const body =
    redirectTo && typeof redirectTo === 'string' ? { redirectTo } : {};

  return async () => {
    const response = await logout(body);
    if (response.status !== 200) {
      return false;
    } else {
      mutate(SWR_KEYS.customer);
      mutate(SWR_KEYS.alerts);
      mutate(SWR_KEYS.wishlist);
      return true;
    }
  };
}
