import React, { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ErrorCard } from '@components/Alerts';
import { Button, ButtonType } from '@components/Button';
import { Input } from '@components/FormComponents';
import { TextLink } from '@components/Typography/TextLink/TextLink';

interface Handler {
  (e: { email: string; password: string }): void;
}

export function LoginFormError({ message }: { message: string }): ReactElement {
  return <ErrorCard message={message} title="" testid="error-card" />;
}

/**
 * Login form
 * @param handleSubmit function to submit the form
 * @param disabled to disable login button
 * @param title title of the form
 */
export function LoginForm({
  handleSubmit,
  disabled,
}: {
  handleSubmit: Handler;
  disabled: boolean;
}): ReactElement {
  const rhfMethods = useForm<any>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  // workaround to fix focus on inputs that are used on Pure React Carousel
  // https://github.com/express-labs/pure-react-carousel/discussions/367
  const handleInputClick = (e) => {
    e.target.focus();
  };

  return (
    <FormProvider {...rhfMethods}>
      <div>
        <form
          data-testid="login-form"
          autoComplete="off"
          className="flex flex-col gap-4"
          onSubmit={rhfMethods.handleSubmit(handleSubmit)}
        >
          <Input
            label="Email Address"
            name="email"
            type="email"
            placeholder="email@domain.com"
            onClick={(e) => handleInputClick(e)}
          />
          <Input
            label="Password"
            name="password"
            type="password"
            onClick={(e) => handleInputClick(e)}
          />

          <div className="actions">
            <Button
              type={ButtonType.submit}
              disabled={disabled}
              buttonStyle="primary"
              testid="login-button"
              className="mr-5"
            >
              Sign In
            </Button>
            <TextLink
              href="/forgot-password"
              data-testid="forgot-password-link"
            >
              Forgot your password?
            </TextLink>
          </div>
        </form>
      </div>
    </FormProvider>
  );
}
