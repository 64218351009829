import {
  addBreadcrumb,
  Breadcrumb,
  captureException,
  captureMessage,
} from '@sentry/nextjs';

import { passwordRegexAnti } from './regex';

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const validatePassword = (password: string) => {
  let message = '';

  // Check length
  if (password.length < 12) {
    message += `Your password is too short (must be at least 12 characters and you entered ${password.length})`;
  }

  // Check has number
  if (!/\d/.test(password)) {
    message += `${
      message.length > 0 ? '. ' : ''
    }Your password must contain at least one number`;
  }

  // Check has lowercase
  if (!/[a-z]/.test(password)) {
    message += `${
      message.length > 0 ? '. ' : ''
    }Your password must contain at least one lowercase letter`;
  }

  // Check has uppercase
  if (!/[A-Z]/.test(password)) {
    message += `${
      message.length > 0 ? '. ' : ''
    }Your password must contain at least one uppercase letter`;
  }

  // Check what they did for invalid characters
  const invalidChars = new Set(
    [...password.trim().matchAll(passwordRegexAnti)].map((a) => a[0])
  );
  if (invalidChars.size > 0) {
    message += `${
      message.length > 0 ? '. ' : ''
    }You used the following invalid characters: ${[...invalidChars].join(' ')}`;
  }

  return message;
};

/**
 * Send to sentry and our logs if on server
 * @param breadcrumb Sentry Breadcrumb
 */
export const logBreadcrumb = (breadcrumb: Breadcrumb) => {
  addBreadcrumb(breadcrumb); // send to sentry
  if (typeof window === 'undefined') {
    console.log(breadcrumb.message); // send to our logs
    console.log(JSON.stringify(breadcrumb.data)); // send to our logs
  }
};

/**
 * Used to send errors and messages to our internal logs and sentry
 * @param message either a string or an error
 */
export const log = (message: unknown | string | Error, location?: string) => {
  const isServer = typeof window === 'undefined';

  if (message instanceof Error) {
    captureException(message); // send to sentry
    if (isServer || process.env.NEXT_PUBLIC_SERVER_URL?.includes('localhost'))
      console.error(
        `Encountered error, triggered by: ${location}. error: ${message.message}`
      ); // send to our logs
  } else {
    // captureMessage(String(message)); // send to sentry
    if (isServer || process.env.NEXT_PUBLIC_SERVER_URL?.includes('localhost'))
      console.log(message); // send to our logs
  }
};
