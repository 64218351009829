import { useSWRConfig } from 'swr';

import { isErrorResponse } from '@interfaces/BulkAPI';
import { SWR_KEYS } from '@lib/constants';

export function useAddItem() {
  const { mutate } = useSWRConfig();

  return async ({
    productId,
  }: {
    productId: number;
  }): Promise<{
    data: null;
    error: string | null;
  }> => {
    const res = await fetch('/api/wishlist/add-item', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        item: {
          productId,
        },
      }),
    }).then((res) => res.json());

    // Can we be more useful with error message
    if (isErrorResponse(res)) {
      return {
        data: null,
        error: res.error.message.join('. '),
      };
    }

    mutate(SWR_KEYS.wishlist);

    return {
      data: null,
      error: null,
    };
  };
}
