/**
 * General button component.
 * Pass in the type, disabled if needed, and set secondary or primary colour (default is type=button primary)
 */

import Link from 'next/link';
import React, { ComponentPropsWithoutRef, ReactElement } from 'react';

import { mergeStyles } from '@lib/styles';

export const ButtonType = {
  button: 'button',
  submit: 'submit',
} as const;

export enum ButtonStyle {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  cta = 'cta',
  text = 'text',
}

const BASE_STYLE = `
  text-white py-1.5 px-7 cursor-pointer tracking-wider font-bold font-secondary uppercase text-xl 
  flex items-center justify-center gap-1
  border-none
  h-10 text-nowrap text-ellipsis overflow-hidden whitespace-nowrap
  focus:ring-2
  disabled:bg-grey-mid disabled:border-grey-mid disabled:border disabled:cursor-not-allowed disabled:font-normal
  disabled:dark:bg-grey-darkest disabled:dark:border-grey-darkest disabled:dark:text-grey-mid
`;

export const BUTTON_STYLES = {
  primary: `
    bg-orange border-orange
    hover:bg-green hover:border-green
  `,
  secondary: `
    bg-transparent border border-grey-mid text-black
    border border-solid
    hover:bg-grey-light hover:border-green
    disabled:bg-transparent disabled:border-grey-mid disabled:text-grey-mid
    dark:text-white dark:border-white dark:hover:bg-grey-darkest
    disabled:dark:bg-grey-darkest disabled:dark:border-grey-mid
  `,
  tertiary: `
    text-grey-dark 
    bg-transparent
    hover:underline hover:underline-offset-[0.1em]  hover:decoration-3 hover:decoration-green hover:duration-200
    disabled:text-grey-mid disabled:bg-transparent disabled:hover:no-underline
    dark:text-white 
    dark:disabled:decoration-3 dark:disabled:decoration-grey-mid dark:disabled:underline disabled:hover:dark:underline
  `,
  cta: `
    bg-green border-green 
    hover:bg-green-dark hover:border-green-dark
    dark:bg-green dark:border-green
  `,
  text: `
    w-fit normal-case text-base tracking-normal
    border-none
    bg-transparent
    underline underline-offset-[0.1em] decoration-1 decoration-grey-mid
    transition-500 
    font-semibold font-grey-dark
    p-0 m-0
    cursor-pointer
    hover:decoration-3 hover:decoration-orange hover:transition-200 hover:text-black
    text-grey-dark dark:text-white dark:hover:text-grey-mid
    disabled:text-grey-mid disabled:hover:no-underline
  `,
} as const;

interface BaseButtonProps {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  buttonStyle?: keyof typeof BUTTON_STYLES;
  testid?: string;
  className?: string;
  scrollToId?: string;
  href?: string;
}

export interface ButtonProps
  extends BaseButtonProps,
    ComponentPropsWithoutRef<'button'> {}

export interface CustomLinkProps
  extends BaseButtonProps,
    ComponentPropsWithoutRef<'a'> {}

// Button component is wrapped with forwardRef (mainly for tooltips)
// need to pass the forwardRef our typings so it knows what the ref is for
export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  (
    {
      icon,
      children,
      buttonStyle = 'primary',
      onClick,
      testid,
      className,
      scrollToId,
      ...props
    },
    forwardedRef
  ): ReactElement => {
    const buttonTypeStyle = mergeStyles(BUTTON_STYLES[buttonStyle], BASE_STYLE);

    // Check for an icon and no children, always include a px in this case
    const classesToApply =
      icon && !children
        ? mergeStyles('px-1', buttonTypeStyle)
        : buttonTypeStyle;

    // Return a normal anchor tag or Link component if href is passed
    // unfortunately TS is too dumb to split out typings here, so cast the props passed down as CustomLinkProps
    if ('href' in props && typeof props.href === 'string') {
      // If here we can cast the props as CustomLink
      const linkProps = { ...props } as CustomLinkProps;

      if (props.href === '/rewards' || props.href === '/checkout') {
        return (
          <a
            {...linkProps}
            href={props.href}
            className={mergeStyles(className ?? '', classesToApply)}
            ref={forwardedRef as undefined | React.Ref<HTMLAnchorElement>}
          >
            {icon} {children}
          </a>
        );
      }
      // Return a normal Link component for every other href than rewards
      return (
        <Link
          {...linkProps}
          prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
          href={props.href}
          className={mergeStyles(className ?? '', classesToApply)}
          ref={forwardedRef as undefined | React.Ref<HTMLAnchorElement>}
        >
          {icon} {children}
        </Link>
      );
    } else {
      // It is a typical button
      const onClickAction = scrollToId
        ? () => document.getElementById(scrollToId)?.scrollIntoView()
        : (onClick as React.MouseEventHandler<HTMLButtonElement> | undefined);

      // Default the button type to 'button' if not set, plus annoying ts type casting
      const explicitButtonType =
        'type' in props
          ? (props.type as React.ButtonHTMLAttributes<HTMLButtonElement>['type'])
          : 'button';

      return (
        <button
          {...props}
          type={explicitButtonType}
          onClick={onClickAction}
          data-testid={testid}
          className={mergeStyles(className ?? '', classesToApply)}
          ref={forwardedRef as undefined | React.Ref<HTMLButtonElement>}
        >
          {icon} {children}
        </button>
      );
    }
  }
);

Button.displayName = 'Button';
