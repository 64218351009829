import useSWR from 'swr';

import { Customer } from '@interfaces/Customer';
import { SWR_KEYS } from '@lib/constants';
import { log, logBreadcrumb } from '@lib/utils';

const fetcher = async (
  input: RequestInfo,
  init: RequestInit,
  ...args: any[]
) => {
  const res = await fetch(input, init).then((res) => res.json());
  if (res.data) {
    return res.data;
  } else {
    return null;
  }
};

export function useCustomer(): {
  customer: Customer | null;
  isLoading: boolean;
} {
  const {
    data: customer,
    error,
    isLoading,
  } = useSWR<Customer | null>(SWR_KEYS.customer, fetcher, {
    dedupingInterval: 500,
    onErrorRetry: async (error, key, config, revalidate, { retryCount }) => {
      logBreadcrumb({
        category: 'hooks',
        message: `useCustomer request`,
        level: 'error',
      });

      log(`/customer/current: ${error}`);

      // Only retry up to 3 times.
      if (retryCount >= 3) return;

      // Retry after 5 seconds.
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
  });

  return { customer: customer ?? null, isLoading };
}
