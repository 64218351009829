import { useSWRConfig } from 'swr';

import { SWR_KEYS } from '@lib/constants';
import { LoginAPIResponse } from 'pages/api/customer/login';

export function useLogin() {
  const { mutate } = useSWRConfig();

  return async (details: { email: string; password: string }) => {
    const loginRes: LoginAPIResponse = await fetch('/api/customer/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(details),
    }).then((res) => res.json());

    mutate(SWR_KEYS.customer);
    mutate(SWR_KEYS.alerts);
    mutate(SWR_KEYS.wishlist);

    return loginRes;
  };
}
