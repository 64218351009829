import Link from 'next/link';
import React, { ReactElement, ReactNode } from 'react';

import { mergeStyles } from '@lib/styles';

export function TextLink({
  children,
  href,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
  href: string;
} & Record<string, any>): ReactElement {
  return (
    <Link
      prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
      href={href}
      className={mergeStyles(className ?? '', 'clickable')}
      {...props}
    >
      {children}
    </Link>
  );
}

export function TextLinkExternal({
  children,
  href,
  className,
  ...props
}: {
  children: ReactNode;
  href: string;
  className?: string;
} & Record<string, any>): ReactElement {
  return (
    <Link
      href={href}
      className={mergeStyles(className ?? '', 'clickable')}
      {...props}
    >
      {children}
    </Link>
  );
}

export function RewardsLink({
  children,
  href = '/rewards',
  className,
  ...props
}: {
  children: ReactNode;
  href?: string;
  className?: string;
} & Record<string, any>): ReactElement {
  return (
    <a
      className={mergeStyles(className ?? '', 'clickable')}
      href={href}
      {...props}
    >
      {children}
    </a>
  );
}
