import React, { ReactElement } from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';

import { CardContainer } from './CardContainer';

export function ErrorCard({
  title,
  message,
  testid,
}: {
  title: string;
  message: string;
  testid?: string;
}): ReactElement {
  return (
    <CardContainer>
      <h2>{title}</h2>
      <p
        data-testid={testid}
        className="flex items-center justify-evenly gap-4 py-4"
      >
        <span>
          <AiFillExclamationCircle size="30px" color="red" />
        </span>
        {message}
      </p>
    </CardContainer>
  );
}
