import React, { CSSProperties, ReactElement, ReactNode } from 'react';

import { mergeStyles } from '@lib/styles';

/**
 * The base paragraph component. To clamp the paragraph to a max number of lines, specify clampedRows.
 *
 * @param children ReactNode;
 * @param clampedRows number;
 * @returns ReactElement;
 */
export function Paragraph({
  children,
  align,
  className = '',
}: {
  children: ReactNode;
  align?: 'left' | 'right' | 'center';
  className?: string;
}): ReactElement {
  const inlineStyle = {} as CSSProperties;
  if (align) {
    inlineStyle.textAlign = align;
  }

  return (
    <p
      className={mergeStyles(className, 'mx-0 mb-5 mt-2.5 text-base')}
      style={inlineStyle}
    >
      {children}
    </p>
  );
}
