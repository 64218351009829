/* eslint-disable no-unused-expressions */
// @ts-nocheck
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import 'normalize.css';
import '../styles/loyaltylion.css';
import '../styles/reset-min.css';
import '../styles/satellite-min.css';
import '../styles/globals.css';

// import { Oswald, Roboto } from '@next/font/google';
import * as Tooltip from '@radix-ui/react-tooltip';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import localFont from 'next/font/local';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import { UIContextProvider } from '@components/Context/UIContext/UIContext';
import { useCustomer } from '@hooks/customer/useCustomer';
import { isErrorResponse } from '@interfaces/BulkAPI';
import { log, logBreadcrumb } from '@lib/utils';

const UnsupportedBrowserToast = dynamic(() =>
  import(
    '../components/BrowserCompatibilityAlert/BrowserCompatibilityAlert'
  ).then((mod) => mod.UnsupportedBrowserToast)
);

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

declare let Yotpo: any;
declare let yotpo: any;
declare let loyaltylion: any;

// const roboto = Roboto({
//   weight: ['100', '300', '400', '700'],
//   style: ['normal', 'italic'],
//   subsets: ['latin'],
// });
// const oswald = Oswald({ subsets: ['latin'] });
const Geogrotesque = localFont({
  src: [
    { path: '../public/font/Geogrotesque-Regular.otf', weight: '400' },
    {
      path: '../public/font/Geogrotesque-RegularIt.otf',
      weight: '400',
      style: 'italic',
    },
    { path: '../public/font/Geogrotesque-Bold.otf', weight: '600' },
    {
      path: '../public/font/Geogrotesque-BoldIt.otf',
      weight: '600',
      style: 'italic',
    },
    { path: '../public/font/Geogrotesque-SemiBold.otf', weight: '600' },
    {
      path: '../public/font/Geogrotesque-SemiBoldIt.otf',
      weight: '600',
      style: 'italic',
    },
  ],
  display: 'swap', // helps performance on LCP
});
const Tungsten = localFont({
  src: [
    { path: '../public/font/Tungsten-Book.otf', weight: '400' },
    {
      path: '../public/font/Tungsten-Semibold.otf',
      weight: '600',
    },
  ],
  display: 'swap', // helps performance on LCP
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { customer } = useCustomer();
  const [llInit, setLLInit] = useState(false);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  // Firefox scroll fix
  useEffect(() => {
    if (navigator.userAgent.indexOf('Firefox') > -1) {
      document.querySelector('html').style.scrollBehavior = 'auto';
      document.querySelector('body').style.scrollBehavior = 'auto';
    }
  }, []);

  // On first load, init LL and auth customer if available
  useEffect(() => {
    if (llInit === true) return;

    try {
      !(function (t, n) {
        var e = n.loyaltylion || [];
        if (!e.isLoyaltyLion) {
          (n.loyaltylion = e),
            void 0 === n.lion && (n.lion = e),
            (e.version = 2),
            (e.isLoyaltyLion = !0);
          var o = n.URLSearchParams,
            i = n.sessionStorage,
            r = 'll_loader_revision',
            a = new Date().toISOString().replace(/-/g, ''),
            s =
              'function' == typeof o
                ? (function () {
                    try {
                      var t = new o(n.location.search).get(r);
                      return t && i.setItem(r, t), i.getItem(r);
                    } catch (t) {
                      return '';
                    }
                  })()
                : null;
          c(
            'https://sdk.loyaltylion.net/static/2/' +
              a.slice(0, 8) +
              '/loader' +
              (s ? '-' + s : '') +
              '.js'
          );
          var l = !1;
          e.init = function (t) {
            if (l) throw new Error('Cannot call lion.init more than once');
            l = !0;
            var n = (e._token = t.token);
            if (!n) throw new Error('Token must be supplied to lion.init');
            var o = [];
            function i(t, n) {
              t[n] = function () {
                o.push([n, Array.prototype.slice.call(arguments, 0)]);
              };
            }
            '_push configure bootstrap shutdown on removeListener authenticateCustomer'
              .split(' ')
              .forEach(function (t) {
                i(e, t);
              }),
              c(
                'https://sdk.loyaltylion.net/sdk/start/' +
                  a.slice(0, 11) +
                  '/' +
                  n +
                  '.js'
              ),
              (e._initData = t),
              (e._buffer = o);
          };
        }
        function c(n) {
          var e = t.getElementsByTagName('script')[0],
            o = t.createElement('script');
          (o.src = n), (o.crossOrigin = ''), e.parentNode.insertBefore(o, e);
        }
      })(document, window);

      if (customer) {
        const dateTime = `${new Date().toISOString().slice(0, -5)}Z`;
        fetch('/api/customer/loyaltyLion', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            customerId: customer.entityId,
            email: customer.email,
            date: dateTime,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (!isErrorResponse(res)) {
              const { data: token } = res;
              // Check both for init function and no token to prevent double initialising
              if (loyaltylion.init && !loyaltylion.token) {
                loyaltylion.init({
                  token: process.env.NEXT_PUBLIC_LOYALTY_LION_TOKEN,
                  customer: {
                    id: customer.entityId,
                    email: customer.email,
                    name: customer.firstName + customer.lastName,
                  },
                  auth: {
                    date: dateTime,
                    token,
                  },
                });
              }
            }
          });
      } else {
        if (loyaltylion.init && !loyaltylion.token) {
          loyaltylion.init({
            token: process.env.NEXT_PUBLIC_LOYALTY_LION_TOKEN,
          });
        }
      }

      setLLInit(true);
    } catch (err) {
      logBreadcrumb({
        category: 'LoyaltyLion',
        message: `Loyalty Lion failed to load`,
        level: 'error',
      });
      log(err);
    }
  }, []);

  // On customer change (i.e. login) authenticate them with LL
  useEffect(() => {
    if (customer && llInit === true) {
      (async () => {
        const dateTime = `${new Date().toISOString().slice(0, -5)}Z`;
        const { data: token } = await fetch('/api/customer/loyaltyLion', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            customerId: customer.entityId,
            email: customer.email,
            date: dateTime,
          }),
        })
          .then((response) => response.json() as Promise<{ data: string }>)
          .catch((err) => {
            log(err);
            return { token: undefined };
          });

        if (token) {
          setTimeout(() => {
            loyaltylion.authenticateCustomer({
              customer: {
                id: customer.entityId,
                email: customer.email,
                name: customer.firstName + customer.lastName,
              },
              auth: {
                date: dateTime,
                token,
              },
            });
          }, 2000);
        }
      })();
    }
  }, [customer, llInit]);

  // Yotpo
  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, no-undef
      const api = new Yotpo.API(yotpo);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      api.refreshWidgets();
    } catch (err) {
      console.info('no Yotpo yet');
    }
  });

  return (
    <>
      <style jsx global>{`
        :root {
          --tungsten-font: ${Tungsten.style.fontFamily};
          --geogrotesque-font: ${Geogrotesque.style.fontFamily};
        }
      `}</style>

      {/* Google Tag Manager Script */}
      <Script id="gtm-script" strategy="lazyOnload">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          '/wa-bulknutrients';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5M7TVR4');
        `}
      </Script>

      {/* Zendesk widget script */}
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=ab2cdccd-2d9a-4844-81e3-fbb002099458"
        strategy="lazyOnload"
      />

      {/* Yotpo widget script */}
      <Script
        src="//staticw2.yotpo.com/8DgLT2zEsIW6I18fixg3pWuBY1ZFZfOiesrgW55i/widget.js"
        strategy="lazyOnload"
      />

      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Bulk Nutrients | Australia’s first choice for nutritional supplements
        </title>
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://www.schema.org',
              '@type': 'WebSite',
              name: 'Bulk Nutrients',
              url: 'https://www.bulknutrients.com.au/',
            }),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              url: 'https://www.bulknutrients.com.au/',
              potentialAction: {
                '@type': 'SearchAction',
                target:
                  'https://www.bulknutrients.com.au/search-results-page?q={search_term_string}',
                'query-input': 'required name=search_term_string',
              },
            }),
          }}
        />
      </Head>
      <UIContextProvider>
        <Tooltip.Provider>
          {getLayout(<Component {...pageProps} />)}
          <SpeedInsights />
          <UnsupportedBrowserToast />
        </Tooltip.Provider>
      </UIContextProvider>
    </>
  );
}

// eslint-disable-next-line no-restricted-syntax
export default MyApp;
