import { useRouter } from 'next/router';
import { ReactElement } from 'react';

export function ExitPreview(): ReactElement {
  const router = useRouter();
  return (
    <button
      type="button"
      onClick={() => {
        router.push('/api/exit-preview');
      }}
      className="fixed bottom-[80px] left-2.5 z-3 border-none bg-red p-4 text-white"
    >
      Exit Preview
    </button>
  );
}
