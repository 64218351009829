import { useEffect } from 'react';
import useSWR from 'swr';

import { Wishlist } from '@interfaces/Wishlist';
import { SWR_KEYS } from '@lib/constants';
import { log, logBreadcrumb } from '@lib/utils';

export function useWishlist(): {
  data: Wishlist | null;
  isLoading: boolean;
  error: any;
} {
  const apiUrl = new URL('/api/wishlist/get-wishlist', 'https://a');

  const fetcher = async (url) => {
    const res = await fetch(apiUrl.pathname + apiUrl.search).then((res) =>
      res.json()
    );
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  };

  // Get wishlist
  const { data, error, isLoading } = useSWR<Wishlist>(
    SWR_KEYS.wishlist,
    fetcher,
    {
      dedupingInterval: 500,
      onErrorRetry: async (error, key, config, revalidate, { retryCount }) => {
        log(`/get-wishlist: ${error}`);

        // Only retry up to 3 times.
        if (retryCount >= 3) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );
  useEffect(
    () =>
      logBreadcrumb({
        category: 'useWishlist',
        message: `received new wishlist data from BigCommerce`,
        level: 'info',
        data: data,
      }),
    [data]
  );

  return { data: data ?? null, isLoading, error };
}
