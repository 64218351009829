export const CACHE = {
  ambassadorsCache: 'cache/ambassadors.cache',
  ambassadorsMetaDataCache: 'cache/ambassadorsMetaData.cache',
  blogsCache: 'cache/blogs.cache',
  blogsMetaDataCache: 'cache/blogsMetaData.cache',
  categoriesCache: 'cache/categories.cache',
  categoriesMetaDataCache: 'cache/categoriesMetaData.cache',
  categoriesQuickNavDataCache: 'cache/categoriesQuickNavData.cache',
  informationPagesCache: 'cache/informationPages.cache',
  pagesMetaDataCache: 'cache/pagesMetaData.cache',
  productsCache: 'cache/products.cache',
  productsMetaDataCache: 'cache/productsMetaData.cache',
  siteSettingsCache: 'cache/siteSettings.cache',
  authorCache: 'cache/author.cache',
  longTailCache: 'cache/longTail.cache',
  exploreCache: 'cache/explore.cache',
  longTailPPCCache: 'cache/info.cache',
};

export const COOKIES = {
  abTestingCookie: 'ab-optimize',
  csrf: 'CSRF_TOKEN',
};

export const FIXED_PRODUCT_IDS = {
  freeSample: 686,
} as const;

export const SEARCH_INDEXES = {
  PRODUCTS_BEST_SELLING: 'sanity_production_products_best_selling',
  PRODUCTS_FEATURED: 'sanity_production_products_featured',
  PRODUCTS_TOP_RATED: 'sanity_production_top_rated',
  PRODUCTS_PRICE_PER_SERVE_LOW_TO_HIGH: 'sanity_production_price_per_serve_asc',
  PRODUCTS_PRICE_PER_SERVE_HIGH_TO_LOW:
    'sanity_production_price_per_serve_desc',
  PRODUCTS_PRICE_ASC: 'sanity_production_products_price_asc',
  PRODUCTS_PRICE_DESC: 'sanity_production_products_price_desc',
  PRODUCTS_AZ: 'sanity_production_products_az',
  PRODUCTS_ZA: 'sanity_production_products_za',
  PRODUCTS: 'sanity_production_products',
  CATEGORIES: 'sanity_production_categories',
  PAGES: 'sanity_production_pages',
  BLOGS: 'sanity_production_blogs',
  AMBASSADORS: 'sanity_production_ambassadors',
  AUTHORS: 'sanity_production_authors',
} as const;

export const PRODUCT_SEARCH_INDEXES = Object.keys(SEARCH_INDEXES).reduce(
  (acc, key) => {
    if (key.includes('PRODUCTS')) {
      acc.push(SEARCH_INDEXES[key]);
    }
    return acc;
  },
  [] as Array<string>
);

// These keys need to be the same as the SEARCH_INDEXES
export const PRODUCT_SORT_OPTIONS = {
  PRODUCTS: 'Relevant',
  PRODUCTS_BEST_SELLING: 'Best Selling',
  PRODUCTS_FEATURED: 'Featured',
  PRODUCTS_TOP_RATED: 'Top Rated',
  PRODUCTS_PRICE_PER_SERVE_LOW_TO_HIGH: 'Price per serve: low to high',
  PRODUCTS_PRICE_PER_SERVE_HIGH_TO_LOW: 'Price per serve: high to low',
  PRODUCTS_PRICE_ASC: 'Price: low to high',
  PRODUCTS_PRICE_DESC: 'Price: high to low',
  PRODUCTS_AZ: 'Name: A to Z',
  PRODUCTS_ZA: 'Name: Z to A',
};

export const CONFIG = {
  categoryHitsPerPage: 36,
  searchResultsHitsPerPage: 12,
  autocompleteProducts: 4,
  autocompleteBlogs: 4,
  autocompletePopularResults: 8,
  defaultIconSize: '1rem',
};

export const BIG_COMMERCE_CUSTOMER_ATTRIBUTES = {
  ott: 1, // one time token for password resets
  referralCode: 2, // hash of customer id to give in share url and make referrals
  referrerName: 5, // display name that will show to people customer refers
  referredByCode: 6, // when signing up from a referral this is stored to link back to referrer
  referralsMade: 7, // how many referrals have been signed up by this customer
  referralsOrderCount: 8, // how many orders by people referred
  referralsOrderValueTotal: 9, // how much has the PT referrer been worth
  referralsStoreCreditEarnedTotal: 10, // how much store credit have we given them
  storeCreditLastUpdated: 12, // when did we last update their store credit (stored as Date.now() i.e. ms since 1/1/1970 zulu)
};

export const SWR_KEYS = {
  cart: 'cartFetcher',
  customer: '/api/customer/current',
  addresses: '/api/customer/address/get-addresses',
  wishlist: 'wishlistFetcher',
  alerts: '/api/alerts',
  menuBanner: '/api/menuBannerCTAs',
  productsOrdered: '/api/customer/products-ordered',
};

export const CUSTOMER_GROUPS = {
  ALL: 2,
  BANTER: 3,
  AMBASSADORS: 5,
  PT_REFERRAL_TRIAL: 9,
};

export const PT_REFERRAL_GROUPS = [
  CUSTOMER_GROUPS.BANTER,
  CUSTOMER_GROUPS.AMBASSADORS,
  CUSTOMER_GROUPS.PT_REFERRAL_TRIAL,
  CUSTOMER_GROUPS.ALL,
];

export const states: { [index: string]: string } = {
  '': 'Choose a State',
  'Australian Capital Territory': 'Australian Capital Territory',
  'New South Wales': 'New South Wales',
  'Northern Territory': 'Northern Territory',
  Queensland: 'Queensland',
  'South Australia': 'South Australia',
  Tasmania: 'Tasmania',
  Victoria: 'Victoria',
  'Western Australia': 'Western Australia',
};

export const statesMap = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

export const countries: { [index: string]: string } = {
  AU: 'Australia',
  NZ: 'New Zealand',
};

export const MIN_BROWSER_VERSIONS = {
  chrome: 100,
  firefox: 104,
  safari: 14,
  edge: 105,
};

export const SUPPORTED_BROWSERS = [
  {
    name: `Chrome ${MIN_BROWSER_VERSIONS.chrome}+`,
    image: '/images/browser-icons/Browser-chrome-logo-128.webp',
    download: 'https://www.google.com/intl/en_au/chrome/',
  },
  {
    name: `Safari ${MIN_BROWSER_VERSIONS.safari}+`,
    image: '/images/browser-icons/Browser-safari-logo-128.webp',
    download: 'https://support.apple.com/en-au/safari',
  },
  {
    name: `Firefox ${MIN_BROWSER_VERSIONS.firefox}+`,
    image: '/images/browser-icons/Browser-firefox-logo-128.webp',
    download: 'https://www.mozilla.org/en-US/firefox/new/',
  },
  {
    name: `Edge ${MIN_BROWSER_VERSIONS.edge}+`,
    image: '/images/browser-icons/Browser-edge-logo-128.webp',
    download: 'https://www.microsoft.com/en-us/edge',
  },
];

export const FORM_IDS = {
  CUSTOMER_SOURCE: '19829d16-989c-4e4f-9d5c-d119d35f867c',
  SUGGEST_FLAVOUR: '446a7119-ed0c-4147-bd6a-40ec58c3abd9',
  FREE_SAMPLE: '37f4ad71-c813-4481-9274-2c516ead46d0',
};

export const RATE_LIMITING = {
  LIMIT_PER_DURATION: 5,
  DURATION: 300,
};

export const KLAVIYO_LISTS = {
  NEWSLETTER: 'HAdn2c',
  SMS: 'WfDam9',
  FREE_SAMPLE_REQUESTED: 'KTeqHs',
  NOTIFY_STOCK: 'yY5BBD',
};
