import { SanityVariant, Variant } from '@interfaces/Product';

import { CUSTOMER_GROUPS } from './constants';

// check if a product is of type Stack using the SKU
export const isStackSku = (sku: string): boolean =>
  sku ? sku.includes('-STK-') : false;

function isVariant(item: Variant | SanityVariant): item is Variant {
  return item.hasOwnProperty('availableFrom');
}

/**
 * Check if a variant is available for the provided customer group.
 * Order of heirarchy is: public > banter > logged in.
 *
 * @param variant
 * @param customerGroupId
 * @returns available
 */
export function isVariantAvailable(
  variant: Variant | SanityVariant,
  customerGroupId?: number
) {
  if (isVariant(variant)) {
    const publicAvailable =
      new Date(variant.availableFrom).valueOf() <= Date.now();
    const banterAvailable =
      new Date(variant.availableFromBanter).valueOf() <= Date.now();
    const loggedInAvailable =
      new Date(variant.availableFromLoggedIn).valueOf() <= Date.now();

    const available =
      publicAvailable ||
      (banterAvailable && customerGroupId === CUSTOMER_GROUPS.BANTER) ||
      (loggedInAvailable && !!customerGroupId);

    return available;
  } else {
    const publicAvailable =
      new Date(variant.scheduledDatetimeAll).valueOf() <= Date.now();
    const banterAvailable =
      new Date(variant.scheduledDatetimeBanter).valueOf() <= Date.now();
    const loggedInAvailable =
      new Date(variant.scheduledDatetimeLoggedIn).valueOf() <= Date.now();

    const available =
      publicAvailable ||
      (banterAvailable && customerGroupId === CUSTOMER_GROUPS.BANTER) ||
      (loggedInAvailable && !!customerGroupId);

    return available;
  }
}
