import dynamic from 'next/dynamic';
import React, { ReactElement, useEffect, useState } from 'react';
import { BiCartAdd } from 'react-icons/bi';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { BaseProduct } from '@interfaces/Product';

import { Button, ButtonType } from './Button';

const QuickAddModal = dynamic(() =>
  import('../Modal/QuickAddModal').then((mod) => mod.QuickAddModal)
);

export function QuickAddButton({
  product,
  isMobile = false,
  className,
}: {
  product: BaseProduct;
  isMobile?: boolean;
  className?: string;
}): ReactElement {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<undefined | String>();

  useEffect(() => {
    /* this ensure that if the order of products change (ie you unselect a favourited product while viewing it in 'Quick Add'),
     the modal will close and not sure the information for the newly ordered product.
     */
    if (selectedProduct !== product.name) setShowModal(false);
    setSelectedProduct(product.name);
  }, [product]);

  if (!product) return <></>;

  return (
    <BulkErrorBoundary location="QuickAddButton">
      <TooltipWrapper text="Quick Add to Cart">
        <Button
          onClick={() => {
            setShowModal(true);
          }}
          aria-label={'quick add button'}
          type={ButtonType.submit}
          className={className}
          buttonStyle="tertiary"
          icon={<BiCartAdd size="1.5rem" />}
        />
      </TooltipWrapper>
      {showModal && (
        <QuickAddModal
          isMobile={isMobile}
          product={product}
          show={showModal}
          setShow={setShowModal}
        />
      )}
    </BulkErrorBoundary>
  );
}
