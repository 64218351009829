import { useFormContext } from 'react-hook-form';

import { Paragraph } from '@components/Typography/Paragraph/Paragraph';

export function FormErrorsSummary() {
  const { formState } = useFormContext();

  return Object.keys(formState?.errors).length > 0 ? (
    <div className="border border-solid border-grey-mid bg-[hsla(0,100%,50%,0.15)] p-2.5">
      <Paragraph>Please correct these errors:</Paragraph>
      <ul className="m-0">
        {Object.keys(formState?.errors).map((key) => (
          <li key={key}>{formState?.errors[key]?.message as string}</li>
        ))}
      </ul>
    </div>
  ) : null;
}
