// ?= is look ahead. We are matching for
// 1. At least one upper case character
// 2. At least one lower case character
// 3. At least one digit
// 4. At least 12 characters long
export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&()]{12,}$/;

export const passwordRegexAnti = /[^A-Za-z\d@$!%*#?&()]+?/g;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const botsRegex = /bot|crawl|spider/i;

export const phoneNumberRegex = /\d{10,11}/;
