import crypto from 'crypto';

import { GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { NextApiRequest, NextApiResponse } from 'next';

import { BulkAPIResponse } from '@interfaces/BulkAPI';
import { s3Client } from '@lib/S3Client';

export const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

const maxFileSize = 1048576 * 10; // 10 MB

const generateFileName = (bytes = 32) =>
  crypto.randomBytes(bytes).toString('hex');

export type FileUploadAPIResponse = BulkAPIResponse<{
  signedUploadUrl: string;
  signedGetUrl: string;
  fileName: string;
}>;

/**
 * Processes a file to be uploaded into S3 form upload bucket
 * @param req
 * @param res
 * @returns
 */
const processFile = async (
  req: NextApiRequest,
  res: NextApiResponse<FileUploadAPIResponse>
) => {
  const { fileType, fileSize, checksum } = req.body;
  if (!ALLOWED_FILE_TYPES.includes(fileType)) {
    return res.status(401).json({
      error: {
        message: [
          `File type not allowed. Accepted types are ${ALLOWED_FILE_TYPES.map(
            (t) => t.split('/')[1]
          ).join(', ')}`,
        ],
        code: 401,
      },
    });
  }

  if (fileSize > maxFileSize) {
    return res.status(401).json({
      error: {
        message: [
          `File size is too large. Max size is ${Math.floor(
            maxFileSize / 1000000
          )}MB.`,
        ],
        code: 401,
      },
    });
  }

  const fileName = generateFileName();
  const putObjCommand = new PutObjectCommand({
    Bucket: process.env.BULK_AWS_IMAGE_UPLOAD_BUCKET!,
    Key: fileName,
    ContentType: fileType,
    ContentLength: fileSize,
    ChecksumSHA256: checksum,
  });
  // This URL allows the frontend to upload the file directly to S3
  const signedUploadUrl = await getSignedUrl(s3Client, putObjCommand, {
    expiresIn: 3600, // 60 seconds
  });

  // Generate access URL
  const getObjCommand = new GetObjectCommand({
    Bucket: process.env.BULK_AWS_IMAGE_UPLOAD_BUCKET!,
    Key: fileName,
  });
  const signedGetUrl = await getSignedUrl(s3Client, getObjCommand, {
    expiresIn: 432000, // 5 days
  });

  return res
    .status(200)
    .json({ data: { signedUploadUrl, signedGetUrl, fileName } });
};

const handler = async (
  req: NextApiRequest,
  res: NextApiResponse
): Promise<any> => {
  const methods = {
    POST: () => processFile(req, res), // will be defined below
    UNSUPPORTED: () => {
      res.setHeader('Allow', ['POST']);
      res.status(405).send(null); // You should set the Allow header here but I've cut it for brevity
    },
  };
  const action = methods[req.method as string] || methods.UNSUPPORTED;
  return action();
};

// eslint-disable-next-line no-restricted-syntax
export default handler;
