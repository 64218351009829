export interface BulkAPIError {
  message: [string, ...string[]];
  code: number;
}

export interface BulkAPIErrorResponse {
  error: BulkAPIError;
}

export interface BulkAPISuccessResponse<ReturnType> {
  data: ReturnType;
}

export type BulkAPIResponse<SuccessType> =
  | BulkAPIErrorResponse
  | BulkAPISuccessResponse<SuccessType>;

export function isErrorResponse(
  response: BulkAPIResponse<any>
): response is BulkAPIErrorResponse {
  return (response as BulkAPIErrorResponse).error !== undefined;
}
