import React, { ReactElement, ReactNode } from 'react';

export function CardContainer({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <div className="mx-auto my-0 max-w-[clamp(350px,500px,100%)] border border-solid border-grey-mid bg-white p-2.5 text-black dark:bg-grey-darkest dark:text-white">
      {children}
    </div>
  );
}
