let version = 2;

export enum Stores {
  keyvaluepairs = 'keyvaluepairs',
}

export const initIndexedDB = (): Promise<boolean> => {
  let request: IDBOpenDBRequest;
  let db: IDBDatabase;
  return new Promise((resolve) => {
    // open the connection
    request = indexedDB.open('localforage', version);

    request.onupgradeneeded = () => {
      db = request.result;

      // if the data object store doesn't exist, create it
      if (!db.objectStoreNames.contains(Stores.keyvaluepairs)) {
        console.log('not store');
        // db.createObjectStore(Stores.keyvaluespairs, { keyPath: 'id' });
      }
      // no need to resolve here
    };

    request.onsuccess = () => {
      db = request.result;
      version = db.version;
      console.log('request.onsuccess - initDB', version);
      resolve(true);
    };

    request.onerror = () => {
      resolve(false);
    };
  });
};

export const getIndexedDBStoreData = <T>(
  storeName: Stores,
  key: string
): Promise<T> => {
  let request: IDBOpenDBRequest;
  let db: IDBDatabase;
  return new Promise((resolve) => {
    // Open the connection
    request = indexedDB.open('localforage', version);

    // Once open, read the data and resolve with it
    request.onsuccess = () => {
      db = request.result;
      if (!db.objectStoreNames.contains(storeName)) {
        // Don't have the store yet, make it
        console.log('no store, make it');
      } else {
        // All good in the hood
        const tx = db.transaction(storeName, 'readonly');
        const store = tx.objectStore(storeName);
        const res = store.get(key);
        res.onsuccess = () => {
          resolve(res.result);
        };
      }
    };

    request.onupgradeneeded = (event: any) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        // Don't have the store yet, make it
        db.createObjectStore(storeName);
      }
    };
  });
};

export const addIndexedDBData = <T>(
  storeName: string,
  key: string,
  data: T
): Promise<T | string | null> => {
  let request: IDBOpenDBRequest;
  let db: IDBDatabase;
  return new Promise((resolve) => {
    request = indexedDB.open('localforage', version);

    request.onsuccess = () => {
      db = request.result;
      console.log(db.objectStoreNames);
      if (!db.objectStoreNames.contains(storeName)) {
        // Don't have the store yet, make it
        console.log('no store, make it');
        db.createObjectStore(storeName);
      }
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.add(data, key);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };

    request.onupgradeneeded = (event: any) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        // Don't have the store yet, make it
        db.createObjectStore(storeName);
      }
    };
  });
};

export const updateIndexedDBData = <T>(
  storeName: string,
  key: string,
  data: T
): Promise<T | string | null> => {
  let request: IDBOpenDBRequest;
  let db: IDBDatabase;
  return new Promise((resolve) => {
    // Open the connection
    request = indexedDB.open('localforage', version);

    // Once open, update the data and resolve with it
    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.put(data, key);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };

    request.onupgradeneeded = (event: any) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        // Don't have the store yet, make it
        db.createObjectStore(storeName);
      }
    };
  });
};

export const deleteIndexedDBData = (
  storeName: string,
  key: string
): Promise<boolean> => {
  let request: IDBOpenDBRequest;
  let db: IDBDatabase;
  return new Promise((resolve) => {
    // again open the connection
    request = indexedDB.open('localforage', version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.delete(key);

      // add listeners that will resolve the Promise
      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      };
    };
  });
};

export const getLocalStorageData = (key: string): string | null =>
  localStorage.getItem(key);

export const setLocalStorageData = (key: string, value: string) =>
  localStorage.setItem(key, value);
