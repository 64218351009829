import dynamic from 'next/dynamic';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';

import { LoginForm } from '@components/LoginForm';
import { Modal } from '@components/Modal/Modal';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useLogin } from '@hooks/customer/useLogin';
import { isErrorResponse } from '@interfaces/BulkAPI';
import { getErrorMessage } from '@lib/utils';

const LoginFormError = dynamic(() =>
  import('../LoginForm').then((mod) => mod.LoginFormError)
);

/**
 * Login modal component.
 * @param modalTitle is the h2 to show at the top of the modal.
 * @param modalIntent is a string to show after "please log in or create an account ..." i.e. you could say "to finally meet that new years resolution" or something
 */
export function LoginModal({
  showModal,
  setShowModal,
  modalTitle,
  modalIntent,
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  modalIntent?: string;
}): ReactElement {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const login = useLogin();

  const handleSubmit = async (e: { email: string; password: string }) => {
    try {
      setError(false);
      setDisabled(true);
      const customer = await login({
        email: e.email,
        password: e.password,
      });
      if (isErrorResponse(customer))
        throw new Error(customer.error.message.join('. '));
      setSuccess(true);
      setDisabled(false);
      setTimeout(() => {
        setSubmitted(true);
      }, 1000);
    } catch (err) {
      setError(true);
      setErrorMessage(getErrorMessage(err));
      setDisabled(false);
    }
  };

  if (!error && submitted) {
    return <></>;
  }

  return (
    <Modal show={showModal} setShow={setShowModal} className="dark:bg-black">
      <section className="max-w-[500px]">
        <h2 className="py-4">{modalTitle}</h2>
        {modalIntent && (
          <Paragraph>
            <TextLink href="/login">Log in</TextLink> or{' '}
            <TextLink href="/create-account">create an account</TextLink>{' '}
            {modalIntent}
          </Paragraph>
        )}
        {error && <LoginFormError message={errorMessage} />}
        <LoginForm handleSubmit={handleSubmit} disabled={disabled} />
        {success && (
          <Paragraph className="text-green"> Login successful </Paragraph>
        )}
      </section>
    </Modal>
  );
}
