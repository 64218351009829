import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Label } from './FormComponents';

function AutocompleteInput({ ausOnly }: { ausOnly: boolean }) {
  const ref = useRef<HTMLInputElement>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete>();

  const { setValue } = useFormContext();

  // Load in Autocomplete
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
      version: 'weekly',
      libraries: ['places'],
    });

    const options = {
      componentRestrictions: { country: ausOnly ? ['au'] : ['au', 'nz'] },
      fields: ['address_components'],
      strictBounds: false,
      types: ['address'],
    };

    loader
      .load()
      .then((google) => {
        if (ref.current) {
          autocomplete.current = new google.maps.places.Autocomplete(
            ref.current,
            options
          );

          autocomplete.current.addListener('place_changed', () => {
            const place = autocomplete.current?.getPlace();
            if (!place) return;
            const selectedAddress = ref.current?.value.split(',')[0].trim();

            const components =
              place.address_components as google.maps.GeocoderAddressComponent[];
            const unit = components.filter(
              (c) => c.types[0] === 'subpremise'
            )[0]?.long_name;
            const streetNumber = components.filter(
              (c) => c.types[0] === 'street_number'
            )[0]?.long_name;
            const street = components.filter((c) => c.types[0] === 'route')[0]
              ?.long_name;
            const postCode = components.filter(
              (c) => c.types[0] === 'postal_code'
            )[0]?.long_name;
            const suburb = components.filter(
              (c) => c.types[0] === 'locality'
            )[0]?.long_name;
            const state = components.filter(
              (c) => c.types[0] === 'administrative_area_level_1'
            )[0]?.long_name;
            const country = components.filter(
              (c) => c.types[0] === 'country'
            )[0]?.short_name;

            const address1 = `${unit ? unit + '/' : ''}${
              streetNumber ? streetNumber : ''
            } ${street ? street : ''}`.trim();

            if (
              selectedAddress &&
              selectedAddress.toLowerCase() !== address1.toLowerCase()
            ) {
              setValue('address1', selectedAddress);
            } else {
              setValue('address1', address1);
            }

            if (postCode) setValue('postcode', postCode);
            if (suburb) setValue('suburb', suburb);
            if (state) setValue('state', state);
            if (country) setValue('country', country);
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <input
      type="text"
      name="googly-boi"
      ref={ref}
      placeholder="Start typing your address and select from options"
      autoComplete="off"
      className="h-10 min-w-[200px] border border-solid border-grey-mid 
      bg-white p-2.5 text-black placeholder:text-grey-dark"
    />
  );
}

export function GoogleAutocomplete({ ausOnly }: { ausOnly: boolean }) {
  return (
    <div className="flex flex-col gap-1">
      <Label label="Address" fieldName="googly-boi" required />
      <AutocompleteInput ausOnly={ausOnly} />
    </div>
  );
}
